<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-6 c g">
            <br>
            <h3 class="text-center g">
                {{ data.schoolname }}
            </h3>
            <div class="card card-body" v-if="!loading">
                <p class="g">
                    الرد على غياب <strong>{{ data.name }}</strong> <br>بتاريخ <strong>{{ data.date }}</strong>
                </p>
                <div class="form-group">
                  <label for="">العذر / الرد</label>
                  <textarea class="form-control" v-model="reason" rows="3" placeholder="اكتب هنا..."></textarea>
                </div>
                <div class="form-group">
                <span for="">ارفاق صورة او ملف (اختياري)</span>
                <input type="file"
                    class="form-control" id="ingredient_file" @change="uploadImage()" accept=".jpg,.png,.gif,.jpeg,.pdf,.doc,.docx,.xls,.xlsx">
                </div>
                <div v-if="uploading" :class="uploading == 1 ? 'g alert alert-warning' : 'g alert alert-success'">
                    {{ uploading_status }}
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="send()">
                        ارسال الرد
                    </button>
                    <br><br>
                    <small class="text-muted">*لن تتمكن من الرد مرة اخرى او تعديل الرد</small>
                </div>
            </div>
            <div class="col-12 text-center g">
                <a href="https://tahdir.net">نظام التحضير الذكي</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            data: {},
            reason: "",
            file: "",
            uploading: 0,
            uploading_status: 'جاري رفع المرفق...'
        }
    },
    created(){
        var g = this;
        $.post(api + '/parents/ghiab/reply-to-ghiab', {
            id: window.location.href.split("-to-ghiab/")[1]
        }).then(function(r){
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.loading = false
                g.data = r.response
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200)
        })
    },
    methods: {
        uploadImage(){
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();

                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());

                $.ajax({
                type: "POST",
                url: api + '/parents/web/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    if(data.status != 100){
                        g.uploading = 0;
                        alert("هذه الصورة او الملف غير مدعوم", 200)
                        return;
                    }
                    g.file = data.response
                    g.uploading = 2;
                    g.uploading_status = "تم رفع المرفق بنجاح!"
                },
                error: function (error) {
                    g.uploading = 0;
                    // handle error
                    alert("حدث خطأ");
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
            g.uploading = 1;
        },
        send(){
            var g = this;
            $.post(api + '/parents/ghiab/reply-to-ghiab-done', {
                id: window.location.href.split("-to-ghiab/")[1],
                file: g.file,
                reason: g.reason
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الارسال بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        }
    }
}
</script>

<style>
.chatbtna_a{
    display: none !important;
}
</style>